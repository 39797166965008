import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const HelpPage = ({ data }) => {

  return (

    <Layout>
      <SEO title="Termos de uso" lang='pt-br' />

      <h2 className="mb-8 bold text-center sm:text-left sm:text-xl">Termos de uso</h2>

      <div className="max-w-4xl text-gray-700">

        <p className="mt-4">
          Leia atentamente os Termos de Uso do site a seguir descritos.
      </p>

        <p className="mt-4">
          Ao acessr este site ou quaisquer de uma suas páginas, o usuário estará concordando com tais termos e condições, sujeitando-se aos mesmos na sua totalidade:
      </p>

        <ul className="ml-8 text-sm" style={{ listStyle: 'upper-roman' }}>

          <li className="mt-8 text-justify">
            O conteúdo disponibilizado neste website tem propósito exclusivamente informativo, não consistindo em recomendações financeiras, legais, fiscais, contábeis ou de qualquer outra natureza. A utilização deste material é de inteira responsabilidade do usuário.
        </li>

          <li className="mt-8 text-justify">
            O website e seus serviços estão sujeitos a eventuais problemas de interrupção, falha técnica ou indisponibilidade de funcionamento temporário.
        </li>

          <li className="mt-8 text-justify">
            Nós e nossos prepostos não se responsabilizarão por qualquer perda ou dano, direto ou indireto, que decorram de: (a) qualquer imprecisão ou falta de complemento das informações, atrasos, interrupções, erros ou omissões no fornecimento dos dados contidos neste site; (b) qualquer decisão ou ação tomada pelo usuário ou terceiros, com base nestas informações.
        </li>

        </ul>

      </div>


    </Layout>
  )
};

export default HelpPage;
